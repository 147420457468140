<template>
    <div class="tw-w-full">
      <loa-der :loading="loading" v-if="loading"/>

    <div class="step-three" v-else>
        <landing-page-nav-bar/>
        <booking-steps />
      <div class="wrapper">
      <div class="back " @click="$router.push({name:'OneWaySelectSeats'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
        <div class="form-input">
            <div class="tw-flex tw-w-full lg:tw-items-center tw-flex-col lg:tw-flex-row tw-justify-between">
              <seat-timer/>
            </div>
          <div v-for="(seat,index) in seats" :key="index">
            <customer-details :index="index" :traveller-detail="seat.travellerDetail"/>
          </div>

            </div>
        <checkout-details />
      <Footer/>
    </div>
    </div>
</template>

<script>
    import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps.vue'
    import CheckoutDetails from '@/components/reuseables/booking/CheckoutDetails.vue'
    import CustomerDetails from '@/components/reuseables/booking/CustomerDetails.vue'
    import LoaDer from "@/components/reuseables/Loader";
    import {mapGetters} from "vuex";
    import SeatTimer from "@/components/reuseables/booking/SeatTimer";
    import  defaultLogo from "@/assets/bluelogo.png"
    import LandingPageNavBar from "@/components/reuseables/LandingPageNavBar.vue";
    import Footer from "@/components/reuseables/Footer.vue";
    export default {
        name: "InputDetails",
        components: {
            Footer,
            LandingPageNavBar,
          SeatTimer,
          LoaDer,
            BookingSteps,
            CheckoutDetails,
                CustomerDetails
        },
      data(){
          return{
            loading : false,
            defaultLogo
          }
      },
      computed:{
          ...mapGetters("tripAndTravellerDetails",["tripAndTravellerDetails"]),
          ...mapGetters('companyProfile',['companyData']),
          ...mapGetters("seatsSelection",["seats"])
      },
      created() {
        // let tripDetails = JSON.parse(sessionStorage.getItem("tripDetails"))
        // let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))

         if (userSelectedTrip && Object.keys(userSelectedTrip).length){
           let currentSelectedSeats = JSON.parse(sessionStorage.getItem('currentSelectedSeats'))
             this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip", userSelectedTrip)
           this.$store.dispatch("seatsSelection/setUserSelectedTrip", userSelectedTrip)

           if(currentSelectedSeats && currentSelectedSeats.seats){
             this.$store.dispatch("seatsSelection/updateCurrentSeats",currentSelectedSeats.seats)
             this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",currentSelectedSeats)
           }
           else {
             this.$store.dispatch("seatsSelection/updateCurrentSeats",currentSelectedSeats)
             this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",currentSelectedSeats)
           }
           this.$store.dispatch("seatsSelection/getTotalAmount", userSelectedTrip.tripAmount)

          // this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails", tripDetails)
        }
      }
    }
</script>

<style lang='scss' scoped>
    .step-three {
        background-color: #fafafa;
    }

    .form-input {
        padding: 2rem 10rem;
        @media (max-width:1024px) {
            padding: 2rem 6rem;
        }
           @media (max-width:1024px) {
            padding: 2rem 6rem;
        }
           @media (max-width:768px) {
            padding: 1rem 2rem;
        }
            @media (max-width:576px) {
            padding: 2rem ;
        }
    }

    .sub-heading {

        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #4F4F4F;
      @media screen and (max-width: 768px) {
        padding-bottom: 1rem;
      }
    }

    .group1 {
        display: flex;
        justify-content: space-between;
    }

    .sub-title {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #004AAD;
            @media (max-width:576px) {
        font-size: 14px;
        }
    }

    .back{
      display: flex;
      align-items: center;
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F;
      width: max-content;
      text-transform: none;
      cursor: pointer;
    }

    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }
    .company-logo{
      width: auto;
      height: 40px;
    }

</style>